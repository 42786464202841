import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SnackbarPaymentMethodAddService {
    private snackbar: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

    setSnackbar(snackbar: string | null): void {
        this.snackbar.next(snackbar);
    }

    getSnackbar(): Observable<string | null> {
        return this.snackbar.asObservable();
    }
}
