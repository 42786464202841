import { Component, OnDestroy, OnInit } from '@angular/core';
import { Cart, PaymentCart, PaymentMethods, SaleStatus } from '@cargos/sprintpay-models';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, switchMap, take, takeUntil } from 'rxjs';
import { CartBillService, PaymentMethodEventFromCart, PaymentMethodsService } from 'src/app/services';
import { SummaryService } from 'src/app/services/summary/summary.service';
import { BannerService } from 'src/app/standalone-components/banner/services/banner.service';
import { PaymentMethodSelected } from 'src/app/utils/cart-types';
import Swal from 'sweetalert2';
import { BreadcrumbService } from 'xng-breadcrumb';
import { CartPayResponse } from './services/models/types';
import { PaymentConfirmationService } from './services/payment-confirmation.service';

@Component({
    selector: 'app-cart',
    templateUrl: './cart.component.html',
    styleUrls: ['./cart.component.scss'],
})
export class CartComponent implements OnInit, OnDestroy {
    private unsubscribe$: Subject<void> = new Subject<void>();
    public paymentRequest: PaymentCart[] = [];
    public paymentConfirmation: boolean;
    public successfulPayments: CartPayResponse[] = [];
    public cartBill: Cart | null;
    public isCartLoading: boolean;
    public paymentMethodAdded: PaymentMethodEventFromCart | null;

    constructor(
        private breadcrumbService: BreadcrumbService,
        private ngxSpinnerService: NgxSpinnerService,
        private paymentConfirmationService: PaymentConfirmationService,
        private cartBillService: CartBillService,
        private paymentMethodsService: PaymentMethodsService,
        private bannerService: BannerService,
        private summaryService: SummaryService
    ) {
        this.breadcrumbService.set('@cart', 'Cart');
    }

    goToCart() {
        this.successfulPayments = [];
        this.bannerEcheck(true);
    }

    ngOnInit(): void {
        this.subscribeToCart();
        this.subscribeIsCartLoading();
        this.subscribePaymentResponse();
        this.displayAlertAboveThreshold();
        this.subscribeBanners();
    }

    ngOnDestroy(): void {
        this.paymentConfirmationService.setPaymentResponse(null);
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private displayAlertAboveThreshold(): void {
        const isCartAboveThreshold = this.cartBillService.isCartAboveThreshold();
        if (isCartAboveThreshold) {
            Swal.fire({
                icon: 'warning',
                width: '718px',
                html: `<p style="font-family: 'Mulish', sans-serif;
                                font-weight: 600;
                                color: #2A2A2A; 
                                font-size: 26px; 
                                margin-inline: 80px;
                                margin-top: 15px">
                            Your cart contains transaction(s) that are not eligible for SprintPay Net Terms
                        </p><br>
                        <p style="color: #2A2A2A; 
                                  font-weight: 400;
                                  font-size: 16px;
                                  margin-bottom: 32px;">
                            Please review your payment method before clicking PAY NOW.
                        </p>`,
                showConfirmButton: true,
                confirmButtonText: 'CONTINUE',
                confirmButtonColor: '#008E69',
                showCancelButton: false,
                allowOutsideClick: false,
            });
        }
    }

    private subscribeToCart(): void {
        this.cartBillService
            .getCartBill$()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (cartBill: Cart | null) => {
                    this.cartBill = cartBill;
                    this.paymentRequest = this.cartBillService.getCartPaymentRequests();
                },
            });
    }

    subscribeIsCartLoading(): void {
        this.cartBillService
            .getIsCartLoading$()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (isLoading) => {
                    this.isCartLoading = isLoading;
                    if (!isLoading) {
                        this.ngxSpinnerService.hide();
                    } else {
                        this.ngxSpinnerService.show();
                    }
                },
            });
    }

    subscribePaymentResponse(): void {
        this.paymentConfirmationService
            .getPaymentResponse()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (cartPayResponses: CartPayResponse[] | null) => {
                    this.successfulPayments =
                        cartPayResponses?.filter((payResult) => payResult.saleStatus === SaleStatus.SALE_SUCCESS) || [];
                    if (this.successfulPayments.length > 0) {
                        this.bannerEcheck(false);
                    }
                },
            });
    }

    private bannerEcheck(display: boolean): void {
        this.bannerService
            .getNotifications()
            .pipe(take(1))
            .subscribe({
                next: (notifications) => {
                    const indexBanner: number = notifications.findIndex(
                        (notification) => notification.name === 'Regions migration'
                    );
                    if (indexBanner != -1) {
                        notifications[indexBanner].display = display;
                        this.bannerService.setNotifications(notifications);
                    }
                },
            });
    }

    private subscribeBanners(): void {
        this.bannerService
            .getNotifications()
            .pipe(
                takeUntil(this.unsubscribe$),
                switchMap(() => {
                    return this.summaryService.getPaymentMethodSelected$();
                })
            )
            .subscribe((paymentMethodByDefault: PaymentMethodSelected | null) => {
                if (sessionStorage.getItem('banners')) {
                    let notifications = JSON.parse(sessionStorage.getItem('banners') || '');

                    const echeckExpired = !!notifications?.find(
                        (notfication) => notfication.name === 'Regions migration' && notfication.styleClass === 'error'
                    );
                    if (
                        echeckExpired &&
                        paymentMethodByDefault?.method === PaymentMethods.ECHECK &&
                        !paymentMethodByDefault.paymentAccount.accountingDetails.isVerified
                    ) {
                        this.summaryService.setPaymentMethodSelected(null);
                    }
                }
            });
    }
}
