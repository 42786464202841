<div class="container-fluid">
    @if(notifications.length){
    <div class="row">
        <div class="col-12">
            @for (notification of notifications; track $index) { @if(!notification.display === false){

            <section class="banner-style" [ngClass]="notification.styleClass">
                <button
                    [id]="'closeBanner'+$index"
                    class="close-banner"
                    aria-label="Close"
                    mat-icon-button
                    (click)="closeBanner(notification.name)"
                >
                    <mat-icon>close</mat-icon>
                </button>
                @if(notification.styleClass === 'success'){
                <mat-icon
                    class="icon-banner"
                    [ngClass]="'icon-'+notification.styleClass"
                    fontIcon="check_circle"
                ></mat-icon>
                } @else{
                <mat-icon
                    class="icon-banner"
                    [ngClass]="'icon-'+notification.styleClass"
                    fontIcon="{{notification.styleClass}}"
                ></mat-icon>
                }
                <div class="description">
                    <span class="p-0 my-1 w-100" [innerHTML]="notification.message | sanitizer: 'html'"></span>
                </div>
            </section>
            } }
        </div>
    </div>
    }
</div>
