export type NotificationsType = {
    name: string;
    alert: boolean;
    type: TypeNotificationType;
    message: string;
    buttonText?: string | null;
    display?: boolean;
    styleClass?: 'info' | 'warning' | 'error' | 'success' | null;
};

export enum TypeNotificationType {
    banner = 'banner',
    alert = 'pop-up',
}
