import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Subject, takeUntil } from 'rxjs';
import { PaymentMethodSelected } from 'src/app/utils/cart-types';
import { SnackbarPaymentMethodAddService } from './services/snack-bar-payment-method-add.service';

@Component({
    standalone: true,
    selector: 'app-snack-bar-payment-method-add',
    templateUrl: './snack-bar-payment-method-add.component.html',
    styleUrl: './snack-bar-payment-method-add.component.scss',
    imports: [CommonModule, MatIconModule, MatButtonModule],
})
export class SnackBarPaymentMethodAddComponent implements OnInit, OnDestroy {
    private unsubscribe$: Subject<void> = new Subject();
    public paymentMethod: PaymentMethodSelected | null;
    public snackbar: string | null;

    @Input() method: string | undefined;
    @Input() lastFourDigit: string | undefined;
    @Output() eventOnClose: EventEmitter<boolean> = new EventEmitter();

    constructor(private snackbarPaymentMethodAddService: SnackbarPaymentMethodAddService) {}

    ngOnInit(): void {
        this.snackbarPaymentMethodAddService
            .getSnackbar()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((snackbar: string | null) => {
                this.snackbar = snackbar;
                if (snackbar) {
                    setTimeout(() => {
                        this.close();
                    }, 10000);
                }
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    close(): void {
        this.snackbarPaymentMethodAddService.setSnackbar(null);
    }
}
