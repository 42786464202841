<!--START: ECHECKS TITLE & CREATE BUTTON -->
<div mat-subheader>
    <div class="d-flex w-100 mat-subheader__container justify-content-between align-items-center">
        <span class="d-flex align-items-center justify-content-start mat-subheader__title mt-3">eChecks</span>
        <div class="d-flex align-items-center justify-content-end mat-subheader__button">
            <button
                id="newEcheck"
                mat-flat-button
                color="primary"
                type="button"
                class="btn__sprintpay btn__sprintpay--green p-0 mt-0 border-0 rounded-0 text-uppercase text-center"
                [routerLink]="'../newPaymentMethods/echeck'"
            >
                New echeck
            </button>
        </div>
    </div>
</div>
<!--END: ECHECKS TITLE & CREATE BUTTON -->
<!--START: ECHECKS LIST -->
<mat-list>
    <!--START: ECHECK LIST ITEM -->
    @if (!deletingEcheck && !isLoading && !!eChecks.length) { @for (eCheck of eChecks; track eCheck) {
    <mat-list-item class="content-item">
        <div class="list-item">
            <div class="echeck-information">
                <div class="content-logo">
                    @if(eCheck.accountingDetails?.isVerified){
                    <img src="assets/images/payment-methods/echeck.svg" alt="eCheck logo" />
                    } @else {
                    <div
                        matBadge="!"
                        matBadgePosition="before"
                        [matBadgeColor]="eCheck?.color"
                        [matTooltip]="eCheck.accountingDetails?.infoMessage || ''"
                    >
                        <img src="assets/images/payment-methods/echeck.svg" alt="eCheck logo" />
                    </div>
                    }
                </div>
                <div>
                    <span>eCheck</span>
                    <div>
                        <span class="account-number"
                            >Account ending **** {{eCheck?.accountingDetails?.accountLastFourDigits}}</span
                        >
                    </div>
                </div>
            </div>
            <div class="echeck-buttons">
                @if(!eCheck.accountingDetails?.isVerified){
                <div>
                    <button
                        [id]="'reverify-'+$index"
                        mat-button
                        type="button"
                        class="mat-focus-indicator btn__sprintpay btn__sprintpay--link text-uppercase p-0 mt-0 border-0 rounded-0"
                        (click)="reverifyECheck(eCheck)"
                        color="primary"
                    >
                        VERIFY
                    </button>
                </div>
                }
                <div>
                    <button
                        id="deleteButton"
                        mat-button
                        type="button"
                        class="mat-focus-indicator btn__sprintpay btn__sprintpay--linkDelete text-uppercase p-0 mt-0 border-0 rounded-0"
                        (click)="deleteECheck(eCheck)"
                        color="warn"
                    >
                        Delete
                    </button>
                </div>
            </div>
        </div>
    </mat-list-item>
    <mat-divider></mat-divider>
    <!--END: ECHECK LIST ITEM -->
    } }
</mat-list>
<!--END: ECHECKS LIST -->
<!--START: NO ECHEKS -->
<ng-container *ngIf="!deletingEcheck && !isLoading && noEchecks">
    <section class="container-fluid">
        <div class="d-flex flex-column h-100 justify-content-center align-items-center mt-5">
            <div class="row">
                <div class="col-12">
                    <img
                        class="mx-auto d-block noPaymentMethod"
                        src="assets/images/home/frequent-facilities.svg"
                        alt="New user illustration"
                    />
                </div>
                <div class="col-12">
                    <h6 class="d-block text-center mt-5 mb-3">
                        Don’t fret! Click “NEW ECHECK” to store your first payment method
                    </h6>
                </div>
            </div>
        </div>
    </section>
</ng-container>
<!--END: NO ECHEKS -->
<!--START: LOADER -->
<div *ngIf="deletingEcheck || isLoading">
    <div class="d-flex justify-content-center">
        <mat-spinner [diameter]="50"></mat-spinner>
    </div>
</div>
<!--END: LOADER -->
