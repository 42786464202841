import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { Router } from '@angular/router';
import { Echeck, PaymentMethod, PaymentMethods } from '@cargos/sprintpay-models';
import { PaymentMethodsRequestService } from '@cargos/sprintpay-services';
import { getEChecks } from '@cargos/sprintpay_frontend_core_api/lib/payment-methods/e-checks/e-check';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject, catchError, from, of, switchMap, take, takeUntil, throwError } from 'rxjs';
import { PaymentMethodsService } from 'src/app/services';
import { EChecksService } from 'src/app/services/utils/echeck.service';
import Swal, { SweetAlertResult } from 'sweetalert2';
@Component({
    selector: 'app-echeck',
    templateUrl: './echeck.component.html',
    styleUrls: ['echeck.component.scss'],
})
export class EcheckComponent implements OnInit, OnDestroy {
    public eChecks: (Echeck & { color?: ThemePalette })[] = [];
    public noEchecks: boolean | undefined;
    public isLoading: boolean = false;
    public deletingEcheck: boolean = false;
    private unsubscribe$: Subject<void> = new Subject<void>();
    private isExpired: boolean;

    constructor(
        private ngxSpinnerService: NgxSpinnerService,
        private paymentMethodsRequestService: PaymentMethodsRequestService,
        private paymentMethodsService: PaymentMethodsService,
        private eChecksService: EChecksService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.echeckExpired();
        this.subscribeToEchecks();
        this.subscribeIsEcheckLoading();
        this.loadEChecks();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private loadEChecks(): void {
        this.ngxSpinnerService.show();
        getEChecks().subscribe({
            next: (echecks: Echeck[]) => {
                this.buildEcheckList(echecks);
            },
            complete: () => {
                this.ngxSpinnerService.hide();
                this.noEchecks = this.eChecks.length === 0;
            },
        });
    }

    /**
     * @method subscribeToEchecks()
     * @description Subscribes to the user's credit cards and updates the component state accordingly
     */
    private subscribeToEchecks(): void {
        this.paymentMethodsService
            .getPaymentMethodsByType$(PaymentMethods.ECHECK)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (paymentMethod: PaymentMethod | undefined) => {
                    this.noEchecks = !paymentMethod?.items?.length;
                    const echecks: Echeck[] = paymentMethod?.items as Echeck[];
                    this.buildEcheckList(echecks);
                },
            });
    }

    /**
     * @method deleteECheck
     * @param {string} eCheckToken - The token of the eCheck to be deleted.
     * @description Prompts the user for confirmation before deleting the specified eCheck.
     * If confirmed, it calls the service to remove the eCheck and updates the payment methods list.
     */
    deleteECheck(eCheck: Echeck): void {
        if (!eCheck) {
            return;
        }

        from(
            Swal.fire({
                text: 'You are about to delete an eCheck',
                title: 'Did you want to continue?',
                icon: 'info',
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Continue',
                cancelButtonText: 'Cancel',
                allowOutsideClick: false,
            })
        )
            .pipe(
                switchMap((result: SweetAlertResult) => {
                    if (!result.isConfirmed) {
                        return of('Complete');
                    }
                    this.deletingEcheck = true;
                    return this.confirmRemoveEcheck(
                        eCheck.paymentToken?.token || '',
                        eCheck.accountingDetails?.isVerified || false
                    ).pipe(
                        take(1),
                        switchMap(() => {
                            return this.paymentMethodsService.getPaymentMethodsRequest$().pipe(take(1));
                        })
                    );
                })
            )
            .subscribe({
                complete: () => {
                    this.deletingEcheck = false;
                },
                error: (error) => {
                    let message: string = 'Something went wrong';
                    if (error.error.error['errors']) {
                        message = error.error.error['errors'][0];
                    }

                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: message,
                        ...error.swalAlertOptions,
                    });
                    this.deletingEcheck = false;
                },
            });
    }

    confirmRemoveEcheck(token: string, isVerified: boolean): Observable<string> {
        if (isVerified) {
            return this.paymentMethodsRequestService.removeBankAccount(Number(token)).pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => ({
                        error,
                        swalAlertOptions: {
                            showConfirmButton: true,
                            showCancelButton: false,
                            allowOutsideClick: false,
                        },
                    }));
                })
            );
        }
        return this.paymentMethodsService.removeEcheck(token).pipe(
            catchError((error: HttpErrorResponse) => {
                return throwError(() => ({
                    error,
                    swalAlertOptions: {
                        showConfirmButton: false,
                        showCancelButton: true,
                        cancelButtonText: 'Cancel',
                        allowOutsideClick: false,
                    },
                }));
            })
        );
    }

    /**
     * @method trackBy()
     * @param (index: number)
     * @param (item: any)
     * @description Compare the current object with the new one; takes the index and the current item as arguments and returns the unique identifier by which that item should be tracked
     */

    trackBy(index: number, item: any): string {
        return item.label;
    }

    subscribeIsEcheckLoading(): void {
        this.paymentMethodsService
            .getPaymentMethodsInProcess$()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (isLoading) => {
                    this.isLoading = isLoading;
                },
            });
    }

    reverifyECheck(echeck: Echeck): void {
        this.eChecksService.setReverifyEcheck(echeck);
        this.router.navigate([`./admin/facilityPayments/paymentMethods/newPaymentMethods/echeck`], {
            queryParams: {
                isReverify: true,
            },
        });
    }

    private buildEcheckList(echecks: Echeck[]): void {
        this.eChecks =
            echecks.map((echeck: Echeck & { color?: ThemePalette }) => {
                echeck.color = 'accent';
                let infoMessage: string;
                if (this.isExpired) {
                    infoMessage = 'Echeck verification is required.';
                    echeck.color = 'warn';
                    if (echeck.accountingDetails) {
                        echeck.accountingDetails.infoMessage = infoMessage;
                    }
                }
                return echeck;
            }) || [];
        this.eChecks = this.eChecks.sort((echeckA, echeckB) => {
            if (
                echeckA.accountingDetails?.isVerified != undefined &&
                echeckB.accountingDetails?.isVerified != undefined
            ) {
                if (echeckA.accountingDetails.isVerified > echeckB.accountingDetails.isVerified) {
                    return 1;
                }
                if (echeckA.accountingDetails.isVerified < echeckB.accountingDetails.isVerified) {
                    return -1;
                }
            }
            return 0;
        });
    }

    private echeckExpired(): void {
        if (sessionStorage.getItem('banners')) {
            let notifications = JSON.parse(sessionStorage.getItem('banners') || '');

            this.isExpired = !!notifications?.find(
                (notfication) => notfication.name === 'Regions migration' && notfication.styleClass === 'error'
            );
        }
    }
}
