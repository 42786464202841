@if(snackbar){
<div class="d-flex justify-content-end">
    <div id="snack-bar-payment-method">
        <mat-icon class="material-symbols-outlined md-icon-16 check-icon"> check_circle </mat-icon>
        <div class="mat-body-1 message m-10">
            <p class="message">{{snackbar}}</p>
        </div>
        <button mat-icon-button (click)="close()">
            <mat-icon> close </mat-icon>
        </button>
    </div>
</div>
}
