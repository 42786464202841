import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { PaymentMethodItem } from '@cargos/sprintpay-models';
import { Observable, Subject, from, of, switchMap, take, takeUntil } from 'rxjs';
import { PaymentMethodsService } from 'src/app/services';
import { SummaryService } from 'src/app/services/summary/summary.service';
import { LastFourDigitsComponent } from 'src/app/standalone-components/last-four-digits/last-four-digits.component';
import { CardTypeComponent } from 'src/app/standalone-components/logo-credit-card/card-type.component';
import Swal from 'sweetalert2';
import { ExpirationDateComponent } from '../expiration-date/expiration-date.component';
import { MethodCreditCardsService } from '../services/method-credit-cards.service';
import { CreditCardsListtMaterialModule } from './credit-cards-list-option-material-modules.module';

@Component({
    standalone: true,
    imports: [
        CommonModule,
        CreditCardsListtMaterialModule,
        CardTypeComponent,
        LastFourDigitsComponent,
        ExpirationDateComponent,
    ],
    selector: 'app-credit-cards-list-option',
    templateUrl: './credit-cards-list-option.component.html',
    styleUrls: ['./credit-cards-list-option.component.scss'],
})
export class CreditCardsListOptionComponent implements OnInit, OnDestroy {
    public token: string | undefined;
    private unsubscribe$: Subject<void> = new Subject<void>();

    @Input() aboveThreshold: boolean = false;
    @Input() loading: boolean = false;
    @Input() creditCardItem: PaymentMethodItem;
    @Input() cardDeletionAllowed: boolean = false;
    @Output() eventOnSelectCreditCard: EventEmitter<string> = new EventEmitter<string>();

    constructor(
        private paymentMethodsService: PaymentMethodsService,
        private methodCreditCardsService: MethodCreditCardsService,
        private summaryService: SummaryService
    ) {}

    ngOnInit(): void {
        this.subscribePaymentMethodSelected();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    selectPaymentMethod(): void {
        if (this.isCardExpired() || !this.creditCardItem.paymentToken?.token) {
            return;
        }

        this.eventOnSelectCreditCard.emit(this.creditCardItem.paymentToken.token);
    }

    isCardExpired(): boolean {
        if (
            !this.creditCardItem?.accountingDetails?.expirationYear ||
            !this.creditCardItem?.accountingDetails?.expirationMonth
        ) {
            return true;
        }

        return this.methodCreditCardsService.expiredCard(
            this.creditCardItem?.accountingDetails?.expirationYear,
            this.creditCardItem.accountingDetails.expirationMonth
        );
    }

    removeCard(card: PaymentMethodItem): void {
        if (!card.paymentToken?.token) {
            return;
        }

        from(
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: 'Yes, delete it!',
                reverseButtons: true,
            })
        )
            .pipe(
                switchMap((result) => {
                    if (!result.isDenied) {
                        return of(null);
                    }
                    this.loading = true;

                    return this.confirmRemoveCard(card.paymentToken?.token || '').pipe(take(1));
                })
            )
            .subscribe({
                next: (deletionConfirmed) => {
                    this.loading = false;
                    if (deletionConfirmed) {
                        Swal.fire('Removed!', '', 'success');
                    }
                },
                error: () => {
                    this.loading = false;
                },
            });
    }

    confirmRemoveCard(token: string): Observable<string> {
        return this.paymentMethodsService.removeCard(token);
    }

    subscribePaymentMethodSelected(): void {
        this.summaryService
            .getPaymentMethodSelected$(this.aboveThreshold)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((paymentMethodSelected) => {
                this.token = paymentMethodSelected?.token;
            });
    }
}
