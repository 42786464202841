<mat-selection-list [multiple]="false" #eChecks>
    <ng-container *ngIf="!loading;">
        @if(echecks?.length){ @for (echeck of echecks; track $index) {
        <div class="echeck-list-item mb-4 p-0 echeck-container">
            @if (echeck.accountingDetails.isVerified) {

            <mat-list-option
                [value]="echeck.paymentToken?.token"
                (click)="selectPaymentMethod(echeck.paymentToken?.token)"
                [selected]="token === echeck.paymentToken?.token"
                checkboxPosition="before"
                color="primary"
            >
                <div class="list-option">
                    <div class="d-flex flex-grow-1">
                        <app-logo-payment-method
                            [paymentMethodName]="paymentMethodsEnum.ECHECK"
                            width="2.3em"
                            class="me-3"
                        ></app-logo-payment-method>
                        @if (echeck.accountingDetails.accountLastFourDigits) {
                        <app-last-four-digits
                            [lastFourDigits]="echeck.accountingDetails.accountLastFourDigits"
                        ></app-last-four-digits>
                        }
                    </div>
                </div>
            </mat-list-option>

            } @else {
            <div class="echeck-element">
                <mat-list-option
                    [value]="echeck.paymentToken?.token"
                    (click)="selectPaymentMethod(echeck.paymentToken?.token, echeck.color)"
                    [selected]="token === echeck.paymentToken?.token"
                    checkboxPosition="before"
                    color="primary"
                    [disabled]="isExpired"
                >
                    <div class="list-option">
                        <div class="d-flex flex-grow-1">
                            <app-logo-payment-method
                                [paymentMethodName]="paymentMethodsEnum.ECHECK"
                                width="2.3em"
                                class="me-3"
                            ></app-logo-payment-method>
                            @if (echeck.accountingDetails.accountLastFourDigits) {
                            <app-last-four-digits
                                [lastFourDigits]="echeck.accountingDetails.accountLastFourDigits"
                            ></app-last-four-digits>
                            }
                            <mat-icon [matTooltip]="echeck.accountingDetails.infoMessage || ''" [color]="echeck.color"
                                >error</mat-icon
                            >
                        </div>
                        @if(echeckDeletionAllowed) {
                        <mat-icon
                            *ngIf="!loading"
                            color="lighter"
                            class="md-icon-24 material-symbols-outlined"
                            (click)="removeEcheck(echeck)"
                            >delete_outline</mat-icon
                        >
                        <mat-icon
                            class="md-icon-24 material-symbols-outlined color-error-300 color-error-default-hover"
                            color="error"
                            *ngIf="loading"
                        >
                            <mat-spinner diameter="20" color="error"></mat-spinner>
                        </mat-icon>
                        }
                    </div>
                </mat-list-option>
            </div>
            <div class="button-container">
                <button
                    [id]="'reverify-'+$index"
                    mat-button
                    type="button"
                    class="mat-focus-indicator btn__sprintpay btn__sprintpay--link text-uppercase p-0 mt-0 border-0 rounded-0"
                    (click)="reverifyECheck(echeck)"
                    color="primary"
                >
                    Verify
                </button>
            </div>
            }
        </div>
        } } @else {
        <app-empty-echeck-list *ngIf="displayEmptyList"></app-empty-echeck-list>
        }
    </ng-container>
</mat-selection-list>
