<div id="admin__main" class="container-fluid overflow-scroll-auto">
    <!-- START: SNACKBAR -->
    <app-snack-bar-payment-method-add></app-snack-bar-payment-method-add>
    <!-- START: BANNER -->
    <app-banner></app-banner>
    <!-- END: BANNER -->
    <div class="p-4">
        <router-outlet></router-outlet>
    </div>
</div>
