import { Injectable } from '@angular/core';
import { Echeck } from '@cargos/sprintpay-models';
import { BehaviorSubject, map, Observable, switchMap } from 'rxjs';
import { EChecksAPIService } from '../requests/echecks-api.service';
import { CustomerService } from './user/customer-handler.service';

@Injectable({ providedIn: 'root' })
export class EChecksService {
    private reverifyEcheck: Echeck | null;
    private selectedEcheck: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    private selectedEcheckInstance: Observable<any> = this.selectedEcheck.asObservable();

    constructor(
        private _customerService: CustomerService,
        private _echecksAPIService: EChecksAPIService
    ) {}

    saveEcheck(echeck: any): void {
        this.selectedEcheck.next(echeck);
    }

    getEcheck(): Observable<any> {
        return this.selectedEcheckInstance;
    }

    hasBankAccounts$(): Observable<boolean> {
        return this._customerService.getActiveProfileView$().pipe(
            switchMap((activeProfileView) => this._echecksAPIService.getBankAccounts(activeProfileView, { size: 1 })),
            map((response) => (response?.totalElements || 0) > 0)
        );
    }

    getReverifyEcheck(): Echeck | null {
        return this.reverifyEcheck;
    }

    setReverifyEcheck(echeck: Echeck | null): void {
        this.reverifyEcheck = echeck;
    }
}
