<!-- <app-breadcrumb></app-breadcrumb> -->
<!-- START: CART -->
<section id="cart">
    <div class="row">
        <!--START: Banner Cart -->
        <ng-container *ngIf="cartBill?.threshold?.message">
            <app-cart-banner></app-cart-banner>
        </ng-container>
        <!--END: Banner Cart -->
        <h1 class="mat-headline-4 mb-3 cart-title" *ngIf="!successfulPayments.length">Checkout</h1>
        <!-- START: PAYMENT REQUESTS LIST AND DETAILS OR EMPTY CART -->
        <ng-container *ngIf="!successfulPayments.length && paymentRequest.length">
            <!-- START: CART DETAILS -->
            <div class="col-md-12 col-lg-7 cart-summary">
                <app-cart-summary></app-cart-summary>
            </div>
            <!-- END: CART DETAILS -->
            <!-- START: PAYMENT DETAILS -->
            <div class="col-md-12 col-lg-5">
                <app-payment-detail-v2> </app-payment-detail-v2>
            </div>
            <!-- END: PAYMENT DETAILS -->
        </ng-container>

        <!-- START: EMPTY CART -->
        <div *ngIf="!isCartLoading && !paymentRequest.length && !successfulPayments.length" class="col-12">
            <app-empty-cart></app-empty-cart>
        </div>
        <!-- END: EMPTY CART -->
        <!-- END: PAYMENT REQUESTS LIST AND DETAILS OR EMPTY CART -->

        <!-- START: PAYMENT RESULT FOR AUTHENTICATED CUSTOMER -->
        <ng-container *ngxPermissionsExcept="'GUEST'">
            <ng-container *ngIf="successfulPayments.length">
                <div class="col-12">
                    <app-payment-confirmation (return)="goToCart()"></app-payment-confirmation>
                </div>
            </ng-container>
        </ng-container>
        <!-- END: PAYMENT RESULT FOR AUTHENTICATED CUSTOMER -->
        <!-- START: PAYMENT RESULT FOR GUEST CUSTOMER -->
        <ng-container *ngxPermissionsOnly="'GUEST'">
            <ng-container *ngIf="successfulPayments.length">
                <div class="col-12">
                    <app-guest-payment-confirmation></app-guest-payment-confirmation>
                </div>
            </ng-container>
        </ng-container>
        <!-- END: PAYMENT RESULT FOR GUEST CUSTOMER -->
    </div>
</section>
<!-- END: CART -->
