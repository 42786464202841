import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Subject, takeUntil } from 'rxjs';
import { NotificationsType } from 'src/app/models/notifications/notifications';
import { PipesSharedModule } from 'src/app/pipes/pipes.module';
import { BannerService } from './services/banner.service';

@Component({
    standalone: true,
    selector: 'app-banner',
    templateUrl: './banner.component.html',
    styleUrls: ['./banner.component.scss'],
    imports: [CommonModule, PipesSharedModule, MatIconModule, MatButtonModule],
})
export class BannerComponent implements OnInit, OnDestroy {
    private unsubscribe$: Subject<void> = new Subject();
    public notifications: NotificationsType[] = [];

    constructor(private bannerService: BannerService) {}

    ngOnInit(): void {
        this.getNotifications();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    getNotifications(): void {
        this.bannerService
            .getNotifications()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (notifications) => {
                    if (notifications.length > 0) {
                        notifications.forEach((notification) => {
                            if (!notification.styleClass) {
                                notification.styleClass = 'info';
                            }
                        });
                    }
                    this.notifications = notifications;
                },
            });
    }

    closeBanner(name: string): void {
        // const filteredNotifications = this.notifications.filter((record) => record === notification);
        const indexNotification = this.notifications.findIndex((record) => record.name === name);
        this.notifications[indexNotification].display = false;

        this.bannerService.updateNotification(this.notifications[indexNotification]);
    }
}
