import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// ROUTING
import { NgxPermissionsModule } from 'ngx-permissions';
import { CartRoutingModule } from './cart-routing.module';

//COMPONENTS
import { AddressInformationComponent } from '@cargos/sprintpay-ui';
import { CompanyFormComponent } from 'src/app/standalone-components/company-form/company-form.component';
import { FormHeaderComponent } from 'src/app/standalone-components/form-header/form-header.component';
import { FormPasswordComponent } from 'src/app/standalone-components/form-password/form-password.component';
import { PersonalInformationFormComponent } from 'src/app/standalone-components/personal-information-form/personal-information-form.component';
import { TermOfUseComponent } from 'src/app/standalone-components/term-of-use/term-of-use.component';
import { VerificationMethodComponent } from 'src/app/standalone-components/verification-method/verification-method.component';
import { AwbFeeComponent } from './awb-fee/awb-fee.component';
import { CartAwbComponent } from './cart-items/cart-awb/cart-awb.component';
import { CartButtonsComponent } from './cart-items/cart-buttons/cart-buttons.component';
import { CartItemDetailsComponent } from './cart-items/cart-item-details/cart-item-details.component';
import { CartItemsComponent } from './cart-items/cart-items.component';
import { CartRatesComponent } from './cart-items/cart-rates/cart-rates.component';
import { CartComponent } from './cart.component';
import { EmptyCartComponent } from './empty-cart/empty-cart.component';
import { GuestPaymentConfirmationComponent } from './guest-payment-confirmation/guest-payment-confirmation.component';
import { PaymentConfirmationComponent } from './payment-confirmation/payment-confirmation.component';
import { PaymentDetailV2Component } from './payment-detail-v2/payment-detail-v2.component';
import { PromoCodeComponent } from './promo-code/promo-code.component';

//MODULES
import { SharedModule } from '../../../shared/shared.module';

// SERVICES
import { PaymentService } from '@cargos/sprintpay-services';
import { NgSelectModule } from '@ng-select/ng-select';
import { AuthenticationFluxService } from 'src/app/modules/two-factor/authentication-flux/authentication-flux.service';
import { TwoFactorModule } from 'src/app/modules/two-factor/two-factor.module';
import { CartBannerComponent } from './cart-banner/cart-banner.component';
import { CartInvoiceComponent } from './cart-items/cart-invoice/cart-invoice.component';
import { CartSummaryComponent } from './cart-summary/cart-summary.component';
import { PayButtonComponent } from './payment-detail-v2/pay-button/pay-button.component';
import { PaypalButtonComponent } from './payment-detail-v2/paypal-button/paypal-button.component';
import { TermsOfUseComponent } from './payment-detail-v2/terms-of-use/terms-of-use.component';
import { GuestPaymentMethodComponent } from './payment-methods-list/guest-payment-method/guest-payment-method.component';
import { PaymentMethodsListComponent } from './payment-methods-list/payment-methods-list.component';
import { CartPayService } from './services/cart-pay.service';
import { PaymentConfirmationService } from './services/payment-confirmation.service';
import { SnackBarPaymentMethodAddComponent } from './snack-bar-payment-method-add/snack-bar-payment-method-add.component';

@NgModule({
    declarations: [
        CartComponent,
        EmptyCartComponent,
        PaymentConfirmationComponent,
        GuestPaymentConfirmationComponent,
        PromoCodeComponent,
        PaymentDetailV2Component,
        CartItemsComponent,
        CartAwbComponent,
        CartItemDetailsComponent,
        CartRatesComponent,
        CartButtonsComponent,
        PayButtonComponent,
        PaypalButtonComponent,
        CartSummaryComponent,
        CartBannerComponent,
        CartInvoiceComponent,
    ],
    imports: [
        AwbFeeComponent,
        CommonModule,
        CartRoutingModule,
        NgxPermissionsModule.forChild(),
        RouterModule,
        ReactiveFormsModule,
        FormsModule,
        SharedModule,
        PersonalInformationFormComponent,
        FormPasswordComponent,
        TermOfUseComponent,
        VerificationMethodComponent,
        CompanyFormComponent,
        FormHeaderComponent,
        AddressInformationComponent,
        TwoFactorModule,
        NgSelectModule,
        TermsOfUseComponent,
        GuestPaymentMethodComponent,
        PaymentMethodsListComponent,
        SnackBarPaymentMethodAddComponent,
    ],
    providers: [PaymentConfirmationService, CartPayService, AuthenticationFluxService, PaymentService],
})
export class CartModule {}
